<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-none d-md-block">
          <carousel />

          <h5 class="Copyright-2021-Dat">
            Copyright © 2021 Data Poem All rights reserved. Privacy and Legal
            Policies
          </h5>
        </div>
        <div class="col-md-6 p-0">
          <div class="py-0 py-md-5">
            <div class="auth-inner p-2 m-0">
              <div class="content-center" style="height: 30%">
                <div class="row p-0 m-0 w-0">
                  <img
                    src="../assets/bitmap.png"
                    srcset="
                      ../assets/bitmap@2x.png 2x,
                      ../assets/bitmap@3x.png 3x
                    "
                    class="Bitmap mb-5"
                  />
                  <h1 class="Hello-welcome-back w-100 mt-3">Registration</h1>
                  <span class="" style="font-size: medium;text-align:center;"
                    >Just a few quick inputs and all-powerful DataPOEM account
                    will be ready!</span
                  >
                </div>
              </div>
              <b-form @submit.stop.prevent="onSubmit" class="w-100 px-5 mt-4">
                <div class="w-100 d-flex">
                  <b-form-group
                    class="w-100 p-2"
                    id="example-input-group-login"
                  >
                    <div
                      class="row w-100 m-0 p-0"
                      style="justify-content: space-between;"
                    >
                      <div
                        class="d-flex px-0 px-md-0 m-0 mb-5 mr-2"
                        style="width: 48%"
                      >
                        <b-form-input
                          style="width: 100%; border: 1px solid #3a424f"
                          :style="
                            form.firstName === ''
                              ? 'color: #9aafd4'
                              : 'color: #222a37'
                          "
                          type="text"
                          class="form-control c-form-input m-0 inputStyle"
                          aria-describedby="firstNameHelp"
                          placeholder="First Name"
                          v-model="$v.form.firstName.$model"
                          :state="validateState('firstName')"
                          name="firstName"
                          :disabled="disableInput"
                        >
                        </b-form-input>
                      </div>
                      <div class="d-flex w-50 px-0 px-md-0 m-0 mb-5">
                        <b-form-input
                          :disabled="disableInput"
                          style="width: 100%; border: 1px solid #3a424f"
                          :style="
                            form.lastName === ''
                              ? 'color: #9aafd4'
                              : 'color: #222a37'
                          "
                          type="text"
                          class="form-control c-form-input m-0 inputStyle"
                          aria-describedby="lastNameHelp"
                          placeholder="Last Name"
                          v-model="$v.form.lastName.$model"
                          :state="validateState('lastName')"
                          name="lastName"
                        >
                        </b-form-input>
                      </div>
                    </div>

                    <div class="d-flex w-100 px-0 px-md-0 row m-0 mb-5">
                      <b-form-input
                        :disabled="disableInput"
                        style="width: 100%; border: 1px solid #3a424f"
                        :style="
                          form.email === ''
                            ? 'color: #9aafd4'
                            : 'color: #222a37'
                        "
                        type="text"
                        class="form-control c-form-input m-0 inputStyle"
                        aria-describedby="firstNameHelp"
                        placeholder="Email"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        name="email"
                        @input="validateEmail"
                      >
                      </b-form-input>
                      <span
                        v-if="!validateEmailDomain"
                        class="py-3"
                        style="font-size: small; color: #ff5a5a;"
                      >
                        Please enter your official email ID
                      </span>
                      <span
                        style="font-size: small; color: #ff5a5a;"
                        v-if="emailExistingNotVerified"
                        >There is already one user created with this email id
                        but not verified the email. Please
                        <span
                          @click="resendOTPToValidate"
                          style="text-decoration: underline;cursor: pointer;color: blue;font-weight: bold;"
                          >Click here</span
                        >
                        to verify the OTP.</span
                      >
                      <span
                        style="font-size: small; color: #ff5a5a;"
                        v-if="emailExisting && !$v.form.email.$error"
                        >This email ID is allready in use. You can log in
                        <span
                          @click="$router.push('/')"
                          style="text-decoration: underline;cursor: pointer;color: blue;font-weight: bold;"
                          >here</span
                        >
                        or reset your password
                        <span
                          @click="$router.push('/forgot-password')"
                          style="text-decoration: underline;cursor: pointer;color: blue;font-weight: bold;"
                          >here.</span
                        >
                      </span>
                    </div>
                    <div class="d-flex w-100 px-0 px-md-0 row m-0 mb-5">
                      <b-form-input
                        :disabled="disableInput"
                        style="width: 100%; border: 1px solid #3a424f"
                        :style="
                          form.company === ''
                            ? 'color: #9aafd4'
                            : 'color: #222a37'
                        "
                        type="text"
                        class="form-control c-form-input m-0 inputStyle"
                        aria-describedby="companyHelp"
                        placeholder="Organization"
                        v-model="$v.form.company.$model"
                        :state="validateState('company')"
                        name="firstName"
                      >
                      </b-form-input>
                    </div>
                    <div
                      class="row w-100 m-0 p-0"
                      style="justify-content: space-between;"
                    >
                      <div
                        class="d-flex px-0 px-md-0 row m-0 mb-5 mr-2"
                        style="width: 48%"
                      >
                        <b-form-input
                          style="width: 100%; border: 1px solid #3a424f"
                          :style="
                            form.password === ''
                              ? 'color: #9aafd4'
                              : 'color: #222a37'
                          "
                          type="password"
                          class="form-control c-form-input m-0 inputStyle"
                          aria-describedby="firstNameHelp"
                          placeholder="Password"
                          v-model="$v.form.password.$model"
                          :state="validateState('password')"
                          name="firstName"
                          :disabled="disableInput"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                          id="input-1-live-feedback"
                          class="w-100"
                          >Password must be at least 6 characters.
                        </b-form-invalid-feedback>
                      </div>

                      <div class="d-flex w-50 px-0 px-md-0 row m-0 mb-5">
                        <b-form-input
                          style="width: 90%; border-left: 1px solid #3a424f;
                        border-top-left-radius:3px;
                        border-bottom-left-radius:3px; margin-right: -1px !important"
                          :style="
                            form.passwordcheck === ''
                              ? 'color: #9aafd4'
                              : 'color: #222a37'
                          "
                          :type="showPassword ? 'text' : 'password'"
                          class="form-control c-form-input m-0 pr-0"
                          id="exampleInputPassword1"
                          aria-describedby="firstNameHelp"
                          placeholder="Re-enter Password"
                          v-model="$v.form.passwordcheck.$model"
                          :state="validateState('passwordcheck')"
                          name="passwordcheck"
                          :disabled="disableInput"
                        >
                        </b-form-input>
                        <span
                          class="content-center inputIcon passwordIcon"
                          :class="{
                            invalid: $v.form.passwordcheck.$error,
                            success:
                              !$v.form.passwordcheck.$error &&
                              $v.form.passwordcheck.$model == '',
                          }"
                          ><img
                            v-if="!showPassword"
                            @click="togglePassword"
                            src="../assets/eye-close-line bold.svg"
                            alt=""
                          />
                          <img
                            v-else
                            @click="togglePassword"
                            src="../assets/eye-line.png"
                            alt=""
                          />
                        </span>
                        <b-form-invalid-feedback
                          v-if="!$v.form.passwordcheck.samesAsPassword"
                          id="input-1-live-feedback"
                          class="w-100"
                          >Passwords do not match .
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div class="w-100 d-flex">
                      <div class="my-4">
                        <div class="w-100" style="float: right">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                            :disabled="disableInput"
                            v-model="$v.form.tos.$model"
                          />
                          <label
                            class="form-check-label tos"
                            for="exampleCheck1"
                            >I agree to all the
                            <span style="text-decoration: underline;"
                            @click="privcyPolicy"
                              >Terms of Use</span
                            >
                            and
                            <span style="text-decoration: underline;"
                            @click="privcyPolicy"
                              >Privacy Policy</span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </div>

                <div
                  class="
                    
                    w-100
                    pr-0 pr-md-2
                    pl-0
                    mt-5 mt-md-3
                  "
                >
                  <div class="w-100 d-flex " style="justify-content: center;">
                    <w-button
                      :buttonText="'Register'"
                      :buttonLoader="buttonLoader"
                      class="w-75"
                      :class="{ resetPasswordLink: isDisable }"
                      :isDisable="isDisable"
                      @buttonClicked="createUser"
                    >
                    </w-button>
                    <!-- <button class="btn btn-primary" @click="googleOauth">Google</button> -->
                  </div>
                  <div
                    class="Forgot-your-password pr-2 mt-3"
                    style="text-align: center"
                  >
                    Already have an account with us?
                    <span
                      style="text-decoration: underline;"
                      @click="$router.push('/')"
                      >Sign In</span
                    >
                    to your account now.
                  </div>
                </div>
              </b-form>
              <div
                class="w-100 text-end mt-4"
                style="font-size: medium;"
                v-if="disableInput"
                @click="disableInput = !disableInput"
              >
                Need to change the email ID? Edit it
                <span
                  style="font-size: medium; text-decoration: underline;font-weight: bold;cursor: pointer;"
                  >here</span
                >
              </div>
              <div class="mt-4" v-if="disableInput">
                <div class="d-flex w-100 px-0 px-md-0 row m-0">
                  <b-form-input
                    style="width: 100%; border: 1px solid #3a424f"
                    :style="
                      form.firstName === ''
                        ? 'color: #9aafd4'
                        : 'color: #222a37'
                    "
                    type="text"
                    class="form-control c-form-input m-0 inputStyle"
                    aria-describedby="firstNameHelp"
                    :placeholder="enterOTPPlaceholder"
                    v-model="$v.form.department.$model"
                    :state="validateState('department')"
                    name="firstName"
                  >
                  </b-form-input>
                  <div
                    class="w-100"
                    style="text-align: center;color: #ff5a5a;font-size: small;"
                    v-if="showOtpError"
                  >
                    <p>{{ errorMessage }}</p>
                  </div>
                  <div
                    class="
                    
                    w-100
                    pr-0 pr-md-2
                    pl-0
                    mt-5 mt-md-3
                  "
                  >
                    <div
                      class="w-100 d-flex "
                      style="justify-content: space-between;"
                    >
                      <w-button
                        :buttonText="'Resend'"
                        :buttonLoader="buttonLoader"
                        class="w-25"
                        @buttonClicked="resendOtp"
                      >
                      </w-button>
                      <w-button
                        :buttonText="'Submit'"
                        :buttonLoader="buttonLoader"
                        class="w-25"
                        @buttonClicked="submitUser"
                        :class="{
                          resetPasswordLink: this.form.department.length !== 6,
                        }"
                        :isDisable="this.form.department.length !== 6"
                      >
                      </w-button>

                      <!-- <button class="btn btn-primary" @click="googleOauth">Google</button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import { required, sameAs, email, minLength } from "vuelidate/lib/validators";
import Carousel from "@/components/Login/Carousel.vue";
import Button from "../components/Profile/Button.vue";
import { LoginServices } from "../services/LoginServices.js";
const loginServices = new LoginServices();
let timeOutValidate;
export default {
  name: "Registration",
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Register yourself with your official email id",
      },
    ],
  },
  mixins: [validationMixin, formMixin],
  components: {
    Carousel,
    "w-button": Button,
  },
  data() {
    return {
      freeEmailList: [],
      buttonLoader: "normal",
      enterOTPPlaceholder: "",
      errors: [],
      disableInput: false,
      showPassword: false,
      showOtpError: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        password: "",
        passwordcheck: "",
        department: "",
        tos: false,
      },
      errorMessage: "Please Type correct OTP",
      validForm: false,
      emailExisting: false,
      emailExistingNotVerified: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      company: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      passwordcheck: {
        required,
        sameAsPassword: sameAs("password"),
        minLength: minLength(6),
      },
      department: {
        required,
      },
      tos: {
        required,
      },
    },
  },
  created() {
    this.getValidationList();
  },
  methods: {
    privcyPolicy() {
      window.open("https://www.datapoem.com/privacy-policy.html", "_blank");
    },
    getValidationList() {
      loginServices.getDomainList().then((res) => {
        this.freeEmailList = res.response;
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateEmail() {
      clearTimeout(timeOutValidate);
      var self = this;
      timeOutValidate = setTimeout(function() {
        if (!self.$v.form.email.$error) {
          self.validateEmailId();
        } else {
          clearTimeout(timeOutValidate);
        }
      }, 5000);
    },
    resendOTPToValidate() {
      this.disableInput = true;
      this.resendOtp();
    },
    validateEmailId() {
      this.emailExisting = false;
      clearTimeout(timeOutValidate);
      loginServices
        .verifyEmail(this.form.email)
        .then((res) => {
          if (
            res.status === "FAILED" &&
            res.failureMessage === "User Already present and Verified"
          ) {
            this.emailExisting = true;
          } else if (
            res.status === "FAILED" &&
            res.failureMessage === "User Already present and not Verified"
          ) {
            this.emailExisting = false;
            this.emailExistingNotVerified = true;
          }
          clearTimeout(timeOutValidate);
        })
        .catch(() => {
          clearTimeout(timeOutValidate);
        });
    },
    createUser() {
      this.buttonLoader = "loader";
      var data = {
        name: this.form.firstName + " " + this.form.lastName,
        password: this.form.passwordcheck,
        email: this.form.email,
        orgName: this.form.company,
        designation: this.form.department,
      };
      this.enterOTPPlaceholder = "Enter OTP sent to " + this.form.email;
      loginServices.registration(data).then((res) => {
        console.log(res);
        this.buttonLoader = "normal";
        if (
          res.status === "FAILED" &&
          res.failureMessage === "User Already present"
        ) {
          this.disableInput = false;
          this.emailExisting = true;
        } else {
          this.disableInput = true;
          this.emailExisting = false;
        }

        // this.$router.push("/");
      });
    },
    submitUser() {
      loginServices
        .verifyOtp(this.form.email, this.form.department)
        .then((res) => {
          console.log(res);
          if (res.status === "FAILED") {
            this.showOtpError = true;
          } else {
            this.$router.push({
              name: "PasswordResetComplete",
              params: {
                message:
                  "Yay! Your registration is successful. You can login to your account by clicking the login button below.",
              },
            });
          }
        });
    },
    resendOtp() {
      this.showOtpError = false;
      loginServices.resendOtp(this.form.email).then((res) => {
        console.log(res);
      });
    },
  },
  computed: {
    validateEmailDomain() {
      if (!this.$v.form.$anyError) {
        var data = this.form.email.split("@")[1];
        if (data !== undefined) {
          if (this.freeEmailList.includes(data.toLowerCase())) {
            return false;
          }
          return true;
        }
        return true;
      }
      return true;
    },
    isDisable() {
      if (
        !this.$v.form.$anyError &&
        this.form.firstName !== "" &&
        this.form.lastName !== "" &&
        this.form.email !== "" &&
        // this.form.company !== "" &&
        this.form.password !== "" &&
        this.form.passwordcheck !== "" &&
        // this.form.department !== "" &&
        this.form.tos !== false &&
        this.buttonLoader !== "loader" &&
        !this.disableInput &&
        this.validateEmailDomain &&
        !this.emailExisting &&
        !this.$v.form.email.$error
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    "$v.form.$anyError"(val) {
      if (!val) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }
    },
  },
};
</script>

<style scoped>
input {
  box-shadow: none !important;
  outline: none !important;
}
input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.form-check {
  padding-left: 0;
}
body {
  /* background: #1C8EF9 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  /* font-family: 'Fira Sans', sans-serif; */

  /* width: 1520px;
  height: 1024px;
  padding: 32px 52px 21px 189.5px; */
  /* background-color: #f2f5fa; */
  /* background-color: #fff !important; */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  /* font-family: 'Fira Sans', sans-serif; */
}

body,
html,
#app,
#root,
.auth-wrapper {
  width: 100%;
  height: auto;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  /* width:450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px; */
  /* border-radius: 15px;
  transition: all .3s; */

  width: 576px;
  /* margin: 0 0 11px 0px; */
  margin-right: 50px;
  padding: 53px 78px 84px 79px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: #ffffff;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/* .Rectangle-Copy {
  width: 696px;
  height: 960px;
  margin: 40px 1px 16px 0;
  padding: 18px 15px 18px 16px;
  object-fit: contain;
  border-radius: 3px;
  background-color: var(--selection);
}  */
/* 
.Rectangle {
  width: 696px;
  height: 960px;
  margin: 0 0 11px 170.5px;
  padding: 153px 118px 284px 119px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: var(--white);
} */

.Bitmap {
  height: 150px;
  object-fit: contain;
}

.Hello-welcome-back {
  width: 100%;
  height: 50px;
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.Tech-Life-Life-Management {
  width: 300px;
  height: 300px;
  margin: 70px 31px 0px;
  padding: 42px 66px 43px 56px;
  object-fit: contain;
}

.Focused-on-results-n {
  /* width: 412px; */
  height: 100px;
  margin-right: 150px;
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.RectangleSmall {
  width: 32px;
  height: 8px;
  margin: 0 16px 0 130px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #222a37;
}

.Oval-Copy-2 {
  width: 8px;
  height: 8px;
  margin: 0 16px;
  object-fit: contain;
  border: solid 1px #222a37;
}

.Oval-Copy-3 {
  width: 8px;
  height: 8px;
  margin: 0 0 0 16px;
  object-fit: contain;
  border: solid 1px #222a37;
}
.inputIcon.invalid {
  color: #f8d8d8 !important;
  background-color: #f8d8d8 !important;
  border: 1px solid #dc3545;
  border-right: 0px;
}
.inputIcon.invalid.passwordIcon {
  border-right: 1px solid #dc3545;
}
.inputIcon.passwordIcon {
  border-right: 1px solid;
  cursor: pointer;
  border-left: 0px !important;
  border-radius: 3px;
}
.inputIcon.success {
  font-family: ProximaNovaBold;
  color: #3a424f;
}
.inputIcon {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #a3b6d9;
  background-color: #eff5ff;
  border: 1px solid;
  border-right: 0px;
  height: 60px;
  width: 10%;
  float: left;
}
.auth-wrapper .form-control:focus {
  border-color: #ced4da;
}
input {
  box-shadow: none;
  outline: none;
}
input:focus {
  box-shadow: none;
  outline: none;
}
.form-check-input:focus {
  box-shadow: none;
  outline: none;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
  outline: none;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #a3b6d9 !important;
}
.form-control {
  border-top: 1px solid #3a424f;
  border-bottom: 1px solid #3a424f;
  box-shadow: none;
}
.inputStyle:focus {
  font-family: ProximaNovaBold;
  color: #3a424f;
}
.inputStyle {
  height: 60px;
  object-fit: contain;
  padding: 18px 30px 18px 6px;
  border-radius: 3px;
  background-image: none;
  color: #a3b6d9;
  /* color: #bccbe5; */
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #eff5ff;
  border-left: 0px;
}

.inputStyle:hover {
  color: #3a424f;
  background-color: #eff5ff;
}

/* #exampleInputEmail1:hover:before{
  opacity: 1;
}

#exampleInputEmail1{
  position: relative;
} */

::placeholder {
  color: #bccbe5;
  opacity: 1; /* Firefox */
}
::placeholder:hover {
  color: #888f9a;
  opacity: 1; /* Firefox */
}
/* ::placeholder {
    padding-left: 20px;
} */
.content-center {
  padding-right: 15px;
}
#exampleInputPassword1 {
  height: 60px;
  object-fit: contain;
  background-image: none !important;

  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: #9aafd4; */
  background-color: #eff5ff;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  color: #9aafd4;
}

#exampleInputPassword1:hover {
  color: #3a424f;
  background-color: #eff5ff;
}

.person {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 385px;
  object-fit: contain;
  right: 450px;
  background-color: #f2f5fa;
}

/* .person_image{
 object-fit: contain;
  background-color: #f2f5fa;
} */
.password {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 485px;
  right: 450px;
  object-fit: contain;
  background-color: #f2f5fa;
}

.form-check-input {
  height: 24px;
  /* margin: 68px 11px 18px 0;
  margin-top: 90px; */
  object-fit: contain;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

.Remember-me {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  cursor: pointer;
  margin: 0px;
}
.tos {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  cursor: pointer;
}

.btn-effect {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect-valid {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}

.Forgot-your-password {
  width: 100%;
  height: 20px;
  right: 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  margin-top: 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;

  /* color: blueviolet; */
}
.Forgot-your-password > span {
  cursor: pointer;
}
.isi {
  width: 190px;
  height: 60px;
  margin: 0 0 0 12px;
  object-fit: contain;
  opacity: 0.5;
}

.Copyright-2021-Dat {
  width: 434px;
  height: 16px;
  /* margin: 153px 200.5px 0 0; */
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  left: -30px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

.bg-img {
  position: absolute;
  top: 0px;
  width: 228px;
  height: 265px;
  background-image: "./assets/Icons/path-17.svg";
}

.eye-line {
  position: absolute;
  top: 538px;
  width: 24px;
  height: 24px;
  margin: 0 0 0 416px;
  object-fit: contain;
  color: #9aafd4;
  cursor: pointer;
}

.eye-line:hover {
  color: #3a424f;
}

.c-form-input.is-invalid {
  background-color: #f8d8d8 !important;
}

.invalid-feedback {
  font-size: 14px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  box-shadow: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #3a424f !important;
}
@media screen and (max-width: 768px) {
  .auth-inner {
    width: 100%;
  }
}
</style>
